import { useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import 'animate.css';
import './App.css';

import Footer from './components/Footer';
import Navigation from './components/Navigation';
import View from './components/View';

import work from './data/work.md';
import media from './data/media.md';
import film from './data/film.md';
import bio from './data/biography.md';
import upcoming from './data/upcoming.md';
import exhibitions from './data/exhibitions.md';
import contact from './data/contact.md';


function App() {
  const [open, set] = useState(false);

  return (
    <div className="App">
      <header className="">
        <Link className="logo" to="/">Amore Istepan Obsidian</Link>
        <div className="spacer"/>
        <Navigation open={open} set={set} />
      </header>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/work" element={<View url={work}/>}/>
        <Route path="/media" element={<View url={media}/>}/>
        <Route path="/film" element={<View url={film}/>}/>
        <Route path="/bio" element={<View url={bio}/>}/>
        <Route path="/upcoming" element={<View url={upcoming}/>}/>
        <Route path="/exhibitions" element={<View url={exhibitions}/>}/>
        <Route path="/contact" element={<View url={contact}/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}


function Home() {
  return (
    <main>
      <div className="stage"> 
        <img src="art/PHOTO-2024-04-23-10-14-45.jpg" alt="Stage"/>
        {/*
        <video autoPlay={true} muted={true} loop={true} playsInline={true}>
           <source src="art/stage_video.mp4" type="video/mp4" />
           <img src="art/sculptureblanche2023-01.jpeg" alt="Stage"/>
            Your browser does not support the video tag.
        </video>
        {/*
        <h2>
          „<br/>
          <span>When I paint<br/>I am water<br/>Water is<br/></span>
          impatient<br/>“
        </h2>
        */}
      </div>
    </main>
  )
}

export default App;
