import { NavLink } from "react-router-dom";
import './Navigation.css';

const Navigation = ({open, set}) => {
  const style = open ? { width: '100%' } : { width: '0%'};

  const click = e => {
    set(!open)
  }

  return (<>
    <div className="menu" onClick={click}>
      <div className={`hamburger ${open ? 'close' : ''}`}>
        <span></span>
        <span></span>
        <span></span>
      </div>
  </div>
  <div className="overlay" style={style} >
    <nav>
      <NavLink to="work" onClick={click}>Work</NavLink>
      <NavLink to="media" onClick={click}>Media</NavLink>
      <NavLink to="film" onClick={click}>Short Film</NavLink>
      <NavLink to="bio" onClick={click}>Bio</NavLink>
      <NavLink to="exhibitions" onClick={click}>Exhibitions</NavLink>
      <NavLink to="upcoming" onClick={click}>Upcoming</NavLink>
      <NavLink to="contact" onClick={click}>Contact</NavLink>
    </nav>
  </div>
  </>)
}

export default Navigation;
