import {ReactComponent as Fb} from '../img/facebook.svg';
import {ReactComponent as Insta}  from '../img/instagram.svg';

const Footer = _ => {
  return (<>
      <footer>
        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/stefan.goekbas">
          <Fb className="icon"/>
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/amore.istepan.obsidian">
          <Insta className="icon"/>
        </a>
        <div>Copyright 2022 Amore Istepan Obsidian</div>
      </footer>
    </>)
}

export default Footer;
