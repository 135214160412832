import { useState } from 'react';
import ReactMarkdown from 'react-markdown';

const View = ({url}) => {
  const [body, set] = useState('');

  fetch(url).then(d => d.text()).then(d => set(d));

  return (
    <main>
      <ReactMarkdown children={body} />
    </main>)
}

export default View;
